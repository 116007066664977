import { Box, theme } from "@chakra-ui/react";
import LoadingLogo from "components/loading/LoadingLogo";
import TextEffect from "components/shared/TextEffect";
import { loadingContainerVars, loadingContentVars } from "constants/animation";
import { INIT_LOADING_CONTENT } from "constants/app";
import { AnimatePresence, motion } from "framer-motion";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "redux/root-reducer";
import { AppDispatch } from "redux/root-store";
import { setInitLoading, setShowLoadingScreen } from "redux/ui/slice";
import { colors } from "theme";
import { throwRandomString } from "utils/string-helper";

export const AppLoadingScreen = () => {
  const dispatch: AppDispatch = useDispatch();
  const isShowLoadingScreen = useSelector((state: RootState) => state.ui.isShowLoadingScreen);

  const initLoading = useSelector((state: RootState) => state.ui.initLoading);

  useEffect(() => {
    const initTimeout = setTimeout(() => {
      dispatch(setInitLoading(false));
    }, 2000);

    return () => clearTimeout(initTimeout);
  }, [dispatch]);

  useEffect(() => {
    if (isShowLoadingScreen) {
      const loadingTimeout = setTimeout(() => {
        dispatch(setShowLoadingScreen(false));
      }, 1000);

      return () => clearTimeout(loadingTimeout);
    }
  }, [dispatch, isShowLoadingScreen]);

  const renderLoadingContent = () => {
    if (!initLoading) {
      return <LoadingLogo />;
    }

    return (
      <TextEffect
        initialValue={throwRandomString(INIT_LOADING_CONTENT.length)}
        targetValue={INIT_LOADING_CONTENT}
        effectSpeed={20}
        iterations={1}
        color={colors.primaryText}
      />
    );
  };

  return (
    <AnimatePresence>
      {(initLoading || isShowLoadingScreen) && (
        <Box
          as={motion.div}
          variants={loadingContainerVars}
          initial={initLoading ? false : "initial"}
          animate={
            initLoading
              ? {
                  ...loadingContainerVars,
                  transition: undefined,
                }
              : "animate"
          }
          exit="exit"
          pos={"absolute"}
          bgColor={colors.alabaster}
          width={"full"}
          height={"100vh"}
          display={"flex"}
          alignItems={"center"}
          justifyContent={"center"}
          zIndex={theme.zIndices.overlay}
          padding={{ base: 0, "2xl": 100 }}
          overflow={"hidden"}
        >
          <Box
            as={motion.div}
            variants={loadingContentVars}
            initial={initLoading ? false : "initial"}
            animate={
              initLoading
                ? {
                    ...loadingContainerVars,
                    transition: undefined,
                  }
                : "animate"
            }
            exit="exit"
            fontSize={{ base: "2rem", "2xl": "4.375rem" }}
            fontWeight={"600"}
          >
            {renderLoadingContent()}
          </Box>
        </Box>
      )}
    </AnimatePresence>
  );
};

import { Center, Text } from "@chakra-ui/react";
import { Col, Row } from "components/elements";
import { TextEnteringEffect } from "components/shared/TextEnteringEffect";
import { ABOUT_VALUES } from "constants/home";
import { colors } from "theme";

const About = () => {
  return (
    <Center
      flexDirection="column"
      paddingX={{ base: "1rem", sm: "2rem", md: "4rem", "2xl": "12.5rem" }}
      paddingTop={{ base: "2rem", sm: "2rem", md: "4rem", "2xl": "10rem" }}
      paddingBottom={{ base: "2rem", sm: "2rem", md: "4rem", "2xl": "10rem" }}
      backgroundColor={colors.mineShaft}
      color="white"
    >
      <TextEnteringEffect durationValue={0.6}>
        <Col alignItems={"center"}>
          <Text
            fontSize={{ base: "5xl", lg: "6xl" }}
            fontWeight="bold"
            textAlign={"center"}
          >
            MEDIA EFFECTIVENESS
          </Text>
        </Col>
      </TextEnteringEffect>

      <TextEnteringEffect
        durationValue={0.6}
        easeValue={[0.7, 0.3, 0.3, 0.7]}
        delayValue={0.07}
      >
        <Row
          gap={{ base: "5rem", "2xl": "10rem" }}
          mt="5rem"
          flexWrap={{ base: "wrap", "2xl": "nowrap" }}
          justifyContent={"center"}
        >
          {ABOUT_VALUES.map((value) => (
            <Col key={value.title} alignItems="center" gap="1rem">
              <Text color={"google.blue"} fontSize={"6xl"} fontWeight={"bold"}>
                {value.number}
              </Text>
              <Text fontSize={"2xl"}>{value.title}</Text>
            </Col>
          ))}
        </Row>
      </TextEnteringEffect>
    </Center>
  );
};

export default About;

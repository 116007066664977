import { Box, Text } from "@chakra-ui/react";
import { Col } from "components/elements";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "redux/root-reducer";
import { AppDispatch } from "redux/root-store";
import { showDrawer } from "redux/ui/slice";
import { colors } from "theme";

const BurgerBar = ({ barWidth, hoverWidth, transform }: { barWidth: number; hoverWidth: number; transform?: string }) => {
  const isShowDrawer = useSelector((state: RootState) => state.ui.menu.isShowDrawer) || false;
  return (
    <Box
      w={!isShowDrawer ? barWidth : 7}
      border=".13em solid"
      borderRadius=".5em"
      borderColor={colors.primaryText}
      bgColor={colors.primaryText}
      transition="all .35s ease-in"
      transitionDelay={!isShowDrawer ? "0.35s" : ""}
      _groupHover={!isShowDrawer ? { width: hoverWidth } : null}
      transform={isShowDrawer ? transform : ""}
    />
  );
};

const BURGER_BAR_WIDTH = [
  { barWidth: 8, hoverWidth: 6, transform: "rotate(45deg) translateY(0.3rem) translateX(0.4rem)" },
  { barWidth: 10, hoverWidth: 12, transform: "rotate(-45deg) translateY(0rem) translateX(0.1rem)" },
  { barWidth: 6, hoverWidth: 8, transform: "scaleX(0)" },
];

const Navigation = () => {
  const dispatch: AppDispatch = useDispatch();
  const isShowDrawer = useSelector((state: RootState) => state.ui.menu.isShowDrawer) || false;

  const handleOpenDrawer = () => {
    dispatch(showDrawer(!isShowDrawer));
  };

  const renderHamburger = () => (
    <Col role="group" gap="5px" cursor="pointer" alignItems="flex-end" onClick={handleOpenDrawer}>
      <Text display={{ base: "none", lg: "block" }} position="absolute" right={-9} fontSize="sm" transform="rotate(90deg)">
        MENU
      </Text>
      {BURGER_BAR_WIDTH.map(({ barWidth, hoverWidth, transform }) => (
        <BurgerBar key={barWidth} barWidth={barWidth} hoverWidth={hoverWidth} transform={transform} />
      ))}
    </Col>
  );

  return <>{renderHamburger()}</>;
};

export default Navigation;

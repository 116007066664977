import {
  Box,
  Icon,
  Link,
  theme,
  Image,
  Text,
  Flex,
  Center,
} from "@chakra-ui/react";
import { Col } from "components/elements";
import { AppLoadingScreen } from "components/elements/AppLoadingScreen";
import Navigation from "components/layouts/Navigation";
import { SCROLL_TYPE, SOCIAL_LINKS } from "constants/app";
import { motion } from "framer-motion";
import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "redux/root-reducer";
import { colors } from "theme";
import Footer from "components/layouts/Footer";
import { DrawerNavigation } from "components/layouts/DrawerNavigation";
import { useLocation } from "react-router-dom";
import { mainColorsSchemes } from "constants/color-scheme";
import { gdscIcon } from "assets/images";
import useScrollDirection from "hooks/useScrollDirection";

interface Props {
  children: React.ReactNode;
}

const Layout = ({ children }: Props) => {
  const isShowLoadingScreen = useSelector(
    (state: RootState) => state.ui.isShowLoadingScreen
  );
  const initLoading = useSelector((state: RootState) => state.ui.initLoading);
  const isShowDrawer =
    useSelector((state: RootState) => state.ui.menu.isShowDrawer) || false;
  const scrollDirection = useScrollDirection();

  const { pathname } = useLocation();

  const renderSiteName = () => {
    if (isShowLoadingScreen || initLoading) {
      return null;
    }

    return (
      <Col
        justifyContent="center"
        position={{ base: "sticky", lg: "fixed" }}
        backgroundColor="white"
        top={{
          base: scrollDirection === SCROLL_TYPE.DOWN && !isShowDrawer ? -24 : 0,
          lg: 0,
        }}
        left={0}
        height={{ base: "55px", lg: isShowDrawer ? "70px" : "55px" }}
        transition="all .35s ease-in"
        transitionDelay={!isShowDrawer ? "0.35s" : ""}
        width="100vw"
        zIndex={theme.zIndices.docked}
      >
        <Flex
          as={motion.div}
          initial={{ y: "-16px" }}
          animate={{
            y: "0px",
            transition: {
              duration: 0.5,
              ease: [1, 0, 0, 1],
              delay: 0.65,
            },
          }}
          ml={{ base: "1rem", sm: "2rem", "2xl": "3.4375rem" }}
          pr={{ base: "1rem", sm: "2rem", lg: "0" }}
          fontWeight={"600"}
          letterSpacing={2}
          justifyContent={"space-between"}
        >
          <Text display={{ base: "none", lg: "block" }}>
            Google Developer Student Club - Danang University of Science and
            Technology
          </Text>
          <Image
            display={{ base: "block", lg: "none" }}
            w="50px"
            src={gdscIcon}
            alt="gdscIcon"
          />
          <Center display={{ base: "flex", lg: "none" }}>
            <Navigation />
          </Center>
        </Flex>
      </Col>
    );
  };

  const renderMenuBtn = () => {
    return (
      <Box
        position="fixed"
        right={0}
        top={{ base: "55px", lg: isShowDrawer ? "70px" : "55px" }}
        width={isShowDrawer ? "80%" : "55px"}
        height={{
          base: "calc(100vh - 55px)",
          lg: isShowDrawer
            ? "calc(100vh - 70px - 55px)"
            : "calc(100vh - 55px - 55px)",
        }}
        backgroundColor="white"
        zIndex={theme.zIndices.docked}
        transition="all .35s ease-in"
        transitionDelay={!isShowDrawer ? "0.35s" : ""}
        visibility={
          isShowDrawer ? "visible" : { base: "hidden", lg: "visible" }
        }
      >
        <DrawerNavigation />
      </Box>
    );
  };

  const renderSocialLinks = () => {
    if (isShowLoadingScreen || initLoading) {
      return null;
    }

    return (
      <Col
        justifyContent="center"
        position="fixed"
        backgroundColor="white"
        height="100vh"
        width={isShowDrawer ? "70px" : "55px"}
        transition="all .35s ease-in"
        transitionDelay={!isShowDrawer ? "0.35s" : ""}
        left={0}
        zIndex={theme.zIndices.docked}
        display={{ base: "none", lg: "block" }}
      >
        <motion.div
          initial={{ left: "0px" }}
          animate={{
            left: "50%",
            transition: {
              duration: 0.5,
              ease: [1, 0, 0, 1],
              delay: 0.65,
            },
          }}
          style={{
            position: "absolute",
            top: "50%",
            transform: "translate(-50%, -50%)",
          }}
        >
          <Col>
            {SOCIAL_LINKS.map((link) => (
              // TODO: need update
              <Link
                href={link.url}
                cursor="pointer"
                target="_blank"
                key={link.title}
              >
                <Icon
                  as={link.icon}
                  boxSize={5}
                  color={colors.primaryText}
                  marginY={3}
                />
              </Link>
            ))}
          </Col>
        </motion.div>
      </Col>
    );
  };

  const renderSiteContent = () => {
    if (isShowLoadingScreen || initLoading) {
      return <Box w={"full"} h={"full"} bg={"white"} position={"fixed"}></Box>;
    }

    return (
      <Box
        backgroundColor={colors.alabaster}
        transition="all 0.35s ease-in"
        transitionDelay={!isShowDrawer ? "0.35s" : ""}
        transitionTimingFunction="cubic-bezier(1, 0, 0, 1)"
        transform={isShowDrawer ? "translateX(-30%)" : ""}
      >
        {children}
      </Box>
    );
  };

  const renderBottomBar = () => {
    return (
      <Box
        position="fixed"
        display={{ base: "none", lg: "block" }}
        backgroundColor="white"
        height="55px"
        width="full"
        bottom={0}
        zIndex={theme.zIndices.docked}
      />
    );
  };

  return (
    <Box
      pos="relative"
      h="100vh"
      sx={{
        "::-webkit-scrollbar": {
          display: "none",
        },
      }}
    >
      <AppLoadingScreen />
      <Box
        padding={{ base: 0, lg: "55px" }}
        paddingBottom="0"
        backgroundColor="white"
      >
        {renderSiteName()}
        {renderMenuBtn()}
        {renderSocialLinks()}
        {renderSiteContent()}
        <Footer />
        {renderBottomBar()}
      </Box>
      <Box
        position="fixed"
        top={"50%"}
        right={{ base: 6, lg: isShowDrawer ? 200 : 10 }}
        zIndex={theme.zIndices.docked}
        transition="all .35s ease-in"
        transitionDelay={!isShowDrawer ? "0.35s" : ""}
        display={{ base: "none", lg: "block" }}
      >
        <Navigation />
      </Box>
      {!isShowLoadingScreen && (
        <Box
          position="fixed"
          top={0}
          right={0}
          height="100vh"
          width={"full"}
          opacity={!isShowDrawer ? 0 : 0.9}
          zIndex={!isShowDrawer ? -1 : 9}
          transition="all .35s ease-in"
          transitionDelay={!isShowDrawer ? "0.35s" : ""}
          bgColor={mainColorsSchemes[pathname] || colors.primaryText}
        ></Box>
      )}
    </Box>
  );
};

export default Layout;

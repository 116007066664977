import { Col, Row } from "components/elements";
import { Box, Center, Flex, Text } from "@chakra-ui/react";
import { TextEnteringEffect } from "components/shared/TextEnteringEffect";
import { AppTitle } from "components/elements/AppTitle";
import { EVENTS } from "constants/events";
import { colors } from "theme";
import { BsArrowRight } from "react-icons/bs";
import { useState } from "react";
import ImageEnteringEffect from "components/shared/ImageEnteringEffect";
import { motion } from "framer-motion";
import { useHistory } from "react-router-dom";
import { PAGES } from "constants/app";
import { setShowLoadingScreen } from "redux/ui/slice";
import { AppDispatch } from "redux/root-store";
import { useDispatch } from "react-redux";
import TextStroke from "components/elements/TextStroke";

const EventsPage = () => {
  const [accordionId, setAccordionId] = useState<number>(0);
  const history = useHistory();
  const dispatch: AppDispatch = useDispatch();

  const handleClickEvent = (eventId: number) => {
    if (eventId === accordionId) {
      setAccordionId(0);
    } else {
      setAccordionId(eventId);
    }
  };

  const handleClickEventDetails = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>,
    event: any
  ) => {
    e.stopPropagation();
    if (event.details) {
      dispatch(setShowLoadingScreen(true));
      history.push(`${PAGES.EVENTS_PAGE}/${event.id}`);
    }
  };

  return (
    <Col>
      <Flex justifyContent={"space-between"} alignItems={"center"}>
        <Row
          width={{ base: "full", xl: "60%" }}
          mt={{ base: "6rem", md: "10rem" }}
          mb={{ base: "5rem", lg: "10rem" }}
          paddingLeft={{
            base: "2rem",
            sm: "4rem",
            md: "6rem",
            lg: "0",
            xl: "5rem",
          }}
          pr={{ base: "2rem", sm: "4rem", md: "6rem", lg: "0" }}
          flexDirection={{ base: "column", md: "row" }}
          gap={"3rem"}
        >
          <TextEnteringEffect durationValue={0.6} delayValue={1}>
            <AppTitle>EVENTS</AppTitle>
          </TextEnteringEffect>

          <Box>
            <TextEnteringEffect
              durationValue={0.6}
              easeValue={[0.7, 0.3, 0.3, 0.7]}
              delayValue={1}
            >
              <Text fontSize="md" maxWidth="400px" textAlign={"justify"}>
                Với sứ mệnh đã đặt ra, GDSC - DUT thường xuyên tổ chức các hoạt
                động, cuộc thi, sự kiện công nghệ không chỉ dành riêng cho các
                thành viên mà còn cho các bạn trẻ đam mê công nghệ. Các sự kiện
                đã khép lại với những thành công rực rỡ, để lại dấu ấn đậm nét,
                thổi bùng niềm đam mê sáng tạo và lập trình trong cộng đồng công
                nghệ.
              </Text>
            </TextEnteringEffect>
          </Box>
        </Row>

        <TextEnteringEffect
          durationValue={0.6}
          delayValue={0.5}
          easeValue={[0.7, 0.3, 0.3, 0.7]}
          direction="horizontal"
          containerProps={{ display: { base: "none", xl: "block" } }}
        >
          <TextStroke title="Events" />
        </TextEnteringEffect>
      </Flex>

      <Col width="100%" gap={"2rem"}>
        {EVENTS.map((event) => (
          <Flex
            flexDirection={"column"}
            paddingX={{ base: "1rem", md: "4rem", "2xl": "14rem" }}
            paddingY="2rem"
            borderTop="1px"
            borderColor={colors.primaryText}
            onClick={() => handleClickEvent(event.id)}
            cursor="pointer"
            key={event.id}
            _last={{ borderBottom: "1px", paddingBottom: "4rem" }}
          >
            <TextEnteringEffect
              fontSize="15px"
              durationValue={1}
              delayValue={0.5}
            >
              <Row
                marginTop={{ base: "1.5rem", xl: "3rem" }}
                justifyContent="space-between"
                alignItems="center"
              >
                <Text fontSize={{ base: "2rem", xl: "3rem" }} fontWeight="600">
                  {event.name}
                </Text>

                {event.details && (
                  <Center
                    width="50px"
                    height="50px"
                    borderRadius="50%"
                    border="1px solid"
                    borderColor={colors.primaryText}
                    onClick={(e) => handleClickEventDetails(e, event)}
                    flexShrink={0}
                  >
                    <Box as={motion.div}>
                      <BsArrowRight />
                    </Box>
                  </Center>
                )}
              </Row>
            </TextEnteringEffect>

            <Row
              minH="100px"
              mt={{ base: "3rem", xl: "6rem" }}
              gap="30px"
              alignItems={"flex-start"}
              flexDirection={{ base: "column", xl: "row" }}
            >
              {event.description.length > 0 && (
                <Col width={{ base: "full", xl: "50%" }} gap={"2rem"}>
                  {event.description.map((des, index) => (
                    <TextEnteringEffect
                      fontSize="15px"
                      width={"full"}
                      durationValue={1}
                      containerProps={{
                        width: { base: "full", xl: "50%" },
                        key: index,
                      }}
                      textAlign={"justify"}
                    >
                      {des}
                    </TextEnteringEffect>
                  ))}
                </Col>
              )}

              {event.image.length > 0 &&
                event.image.map((img, index) => (
                  <Box width={{ base: "full", xl: "50%" }} key={index}>
                    <ImageEnteringEffect
                      src={img}
                      minH={"350px"}
                      height={"full"}
                      w={"full"}
                      objectFit={"cover"}
                      containerProps={{ width: "full" }}
                      initial={{ scaleX: 1, originX: 1 }}
                      animate={{
                        scaleX: 0,
                        transition: {
                          duration: 0.75,
                          times: [0, 0.3, 1],
                        },
                      }}
                    />
                  </Box>
                ))}
            </Row>
          </Flex>
        ))}
      </Col>
    </Col>
  );
};

export default EventsPage;

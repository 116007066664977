import { Box, Flex, Image, Text, Link as ChakraLink } from "@chakra-ui/react";
import { Col, Row } from "components/elements";
import { AppTitle } from "components/elements/AppTitle";
import { TextEnteringEffect } from "components/shared/TextEnteringEffect";
import { BsArrowLeft } from "react-icons/bs";
import { Variants, motion } from "framer-motion";
import {
  champion,
  final,
  unihack1,
  unihack2,
  unihack2023,
  unihack3,
  unihack4,
  unihack5,
  unihack6,
  unihack7,
} from "assets/images";
import { colors } from "theme";
import ImageEnteringEffect from "components/shared/ImageEnteringEffect";
import { Link as ReactRouterLink } from "react-router-dom";
import { PAGES } from "constants/app";
import { AppDispatch } from "redux/root-store";
import { useDispatch } from "react-redux";
import { setShowLoadingScreen, showDrawer } from "redux/ui/slice";

export interface EventDetailsProps {}

const iconVariants: Variants = {
  hover: {
    x: -8,
  },
};

export default function EventDetails(props: EventDetailsProps) {
  const dispatch: AppDispatch = useDispatch();
  const handlePressDrawerItem = () => {
    dispatch(setShowLoadingScreen(true));
    dispatch(showDrawer(false));
  };

  return (
    <Box>
      <Col
        gap={"1rem"}
        padding={{ base: "2rem", sm: "3rem", md: "7rem" }}
        pb={"3rem"}
      >
        <TextEnteringEffect
          durationValue={0.6}
          delayValue={0.5}
          containerProps={{ overflow: "unset" }}
        >
          <Flex
            as={motion.div}
            gap={"0.5rem"}
            alignItems={"center"}
            whileHover="hover"
            cursor={"pointer"}
          >
            <Box as={motion.div} variants={iconVariants}>
              <BsArrowLeft />
            </Box>
            <ChakraLink
              as={ReactRouterLink}
              to={PAGES.EVENTS_PAGE}
              onClick={handlePressDrawerItem}
              _hover={{ textDecoration: "none" }}
            >
              <Text fontSize="xs" fontWeight="bold">
                Back to the events
              </Text>
            </ChakraLink>
          </Flex>
        </TextEnteringEffect>
        <TextEnteringEffect durationValue={0.6} delayValue={0.8}>
          <AppTitle>UniHack 2023</AppTitle>
        </TextEnteringEffect>
      </Col>
      <Box
        height={"70vh"}
        overflow={"hidden"}
        pos={"relative"}
        _after={{
          content: "''",
          position: "absolute",
          bottom: 0,
          left: 0,
          w: "55%",
          h: 20,
          bg: colors.alabaster,
        }}
      >
        <Image
          as={motion.img}
          w={"full"}
          h={"full"}
          objectFit={"cover"}
          src={unihack2023}
        />
      </Box>
      <Col
        padding={{ base: "2rem", md: "5rem", xl: "7rem", "2xl": "9rem" }}
        pb={{ base: 0, md: 0, xl: 0, "2xl": 0 }}
        gap={{ base: "2rem", xl: "0" }}
      >
        <Row flexDirection={{ base: "column", xl: "row" }}>
          <Col width={{ base: "full", xl: "40%" }} gap={"1rem"}>
            <TextEnteringEffect
              durationValue={0.6}
              easeValue={[0.7, 0.3, 0.3, 0.7]}
              delayValue={0.07}
            >
              <Text
                fontSize="md"
                maxWidth="400px"
                mt={10}
                mb={8}
                textAlign={"justify"}
              >
                Cuộc thi{" "}
                <Text as={"span"} fontWeight={"bold"}>
                  UniHack 2023
                </Text>{" "}
                diễn ra tại Đà Nẵng từ ngày 16-30/7, sự kiện do Câu lạc bộ
                Google Developer Student Club - Chapter Trường Đại học Bách khoa
                - Đại học Đà Nẵng (GDSC-DUT) phối hợp cùng Google Developer
                Group Cloud Đà Nẵng tổ chức.{" "}
                <Text as={"span"} fontWeight={"bold"}>
                  UniHack 2023
                </Text>{" "}
                là cuộc thi Hackathon dành cho các bạn sinh viên đam mê công
                nghệ và lập trình chung tay vào việc xây dựng những dự án công
                nghệ và giải pháp đột phá mang ý nghĩa sâu sắc cho cộng đồng và
                xã hội.
              </Text>
            </TextEnteringEffect>
          </Col>
          <Col width={{ base: "full", xl: "60%" }}>
            <ImageEnteringEffect
              src={unihack1}
              mt={{ base: "0", xl: "5rem" }}
              height={"800px"}
              width={{ base: "full", xl: "80%" }}
              objectFit={"cover"}
              containerProps={{ width: "full" }}
              initial={{ scaleY: 1, originY: 1 }}
              animate={{
                scaleY: 0,
                transition: {
                  duration: 0.75,
                  delay: 0.5,
                  times: [0, 0.3, 1],
                },
              }}
            />
          </Col>
        </Row>
        <Row>
          <Col width={{ base: "full", xl: "40%" }}>
            <ImageEnteringEffect
              src={unihack2}
              height={"500px"}
              w={"full"}
              objectFit={"cover"}
              containerProps={{ width: "full" }}
              initial={{ scaleX: 1, originX: 1 }}
              animate={{
                scaleX: 0,
                transition: {
                  duration: 0.75,
                  delay: 0.5,
                  times: [0, 0.3, 1],
                },
              }}
            />
          </Col>
          <Col width={"60%"} display={{ base: "none", xl: "flex" }}></Col>
        </Row>
      </Col>
      <Row
        padding={{ base: "2rem", md: "5rem", xl: "7rem", "2xl": "9rem" }}
        pb={{ base: 0, md: 0, xl: 0, "2xl": 0 }}
        flexDirection={{ base: "column", xl: "row" }}
        gap={{ base: "4rem" }}
      >
        <Col width={{ base: "full", xl: "35%" }} gap={"1rem"}>
          <TextEnteringEffect
            durationValue={0.6}
            easeValue={[0.7, 0.3, 0.3, 0.7]}
            delayValue={0.07}
          >
            <Text
              fontSize="md"
              maxWidth="400px"
              mt={10}
              mb={8}
              textAlign={"justify"}
            >
              Cuộc thi là sân chơi để các bạn có cơ hội được cọ xát, vượt qua
              giới hạn của bản thân và trải nghiệm thực chiến trong 24 giờ đồng
              hồ lập trình liên tục. Với chủ đề{" "}
              <Text as={"span"} fontWeight={"bold"}>
                Tech for Environment
              </Text>
              ,{" "}
              <Text as={"span"} fontWeight={"bold"}>
                UniHack 2023
              </Text>{" "}
              hướng đến thúc đẩy sự thay đổi và nâng cao nhận thức về bảo vệ môi
              trường. Chủ đề của cuộc thi được xây dựng dựa trên 3 trong 7 mục
              tiêu phát triển bền vững của Liên hợp quốc (SDGs). Cuộc thi với 25
              đội đăng ký tham gia. TOP 10 đội thi xuất sắc nhất bước vào phần
              thuyết trình và thuyết phục ban giám khảo để giành giải thưởng cao
              nhất.
            </Text>
          </TextEnteringEffect>
          <ImageEnteringEffect
            src={unihack3}
            mt={{ base: "0", xl: "5rem" }}
            height={"600px"}
            width={{ base: "full", "2xl": "65%" }}
            objectFit={"cover"}
            containerProps={{ width: "full" }}
            initial={{ scaleX: 1, originX: 1 }}
            animate={{
              scaleX: 0,
              transition: {
                duration: 0.75,
                times: [0, 0.3, 1],
              },
            }}
          />
        </Col>
        <Row
          width={{ base: "full", xl: "65%" }}
          gap={{ base: "1rem", lg: "4rem" }}
        >
          <ImageEnteringEffect
            src={unihack4}
            mt={{ base: "5rem", xl: "10rem" }}
            height={"600px"}
            w={{ base: "full", "2xl": "80%" }}
            objectFit={"cover"}
            containerProps={{ width: "full" }}
            initial={{ scaleX: 1, originX: 1 }}
            animate={{
              scaleX: 0,
              transition: {
                duration: 0.75,
                times: [0, 0.3, 1],
              },
            }}
          />
          <ImageEnteringEffect
            src={unihack5}
            height={"600px"}
            w={{ base: "full", "2xl": "80%" }}
            objectFit={"cover"}
            containerProps={{ width: "full" }}
            initial={{ scaleX: 1, originX: 1 }}
            animate={{
              scaleX: 0,
              transition: {
                duration: 0.75,
                times: [0, 0.3, 1],
              },
            }}
          />
        </Row>
      </Row>
      <Row
        padding={{ base: "2rem", md: "5rem", xl: "7rem", "2xl": "9rem" }}
        py={{ base: "0", md: "0", xl: "0", "2xl": "0" }}
        gap={{ base: "1rem", lg: "4rem", "2xl": "0" }}
      >
        <Row width={"50%"}>
          <ImageEnteringEffect
            src={unihack6}
            mt={"6rem"}
            height={"600px"}
            w={{ base: "full", xl: "60%" }}
            objectFit={"cover"}
            containerProps={{ width: "full" }}
            containerImgProps={{ display: "flex", justifyContent: "center" }}
            initial={{ scaleX: 1, originX: 1 }}
            animate={{
              scaleX: 0,
              transition: {
                duration: 0.75,
                delay: 0.5,
                times: [0, 0.3, 1],
              },
            }}
          />
        </Row>
        <Row width={"50%"}>
          <ImageEnteringEffect
            src={unihack7}
            height={"600px"}
            w={{ base: "full", xl: "60%" }}
            objectFit={"cover"}
            containerProps={{ width: "full" }}
            containerImgProps={{ display: "flex", justifyContent: "center" }}
            initial={{ scaleX: 1, originX: 1 }}
            animate={{
              scaleX: 0,
              transition: {
                duration: 0.75,
                delay: 0.5,
                times: [0, 0.3, 1],
              },
            }}
          />
        </Row>
      </Row>
      <Col
        padding={{ base: "2rem", md: "5rem", xl: "7rem", "2xl": "9rem" }}
        gap={{ base: "2rem", xl: "8rem" }}
      >
        <Col gap={"4rem"}>
          <TextEnteringEffect
            durationValue={0.6}
            delayValue={0.5}
            textAlign={"justify"}
          >
            Đội thi{" "}
            <Text as={"span"} fontWeight={"bold"}>
              Green Guardians
            </Text>{" "}
            chính thức giành được ngôi vị Quán quân của cuộc thi{" "}
            <Text as={"span"} fontWeight={"bold"}>
              UniHack 2023
            </Text>{" "}
            với dự án{" "}
            <Text as={"span"} fontWeight={"bold"}>
              Green Sorter
            </Text>{" "}
            - Ứng dụng cung cấp kiến thức và hỗ trợ phân loại rác thải. Với trí
            tuệ nhân tạo (AI) cùng khả năng nhận dạng hình ảnh, ứng dụng này
            giúp người dùng phân loại rác một cách chính xác và nhanh chóng.
            Điều này không chỉ giúp tiết kiệm thời gian, nỗ lực của người dùng
            mà còn bảo đảm sự chính xác và hiệu quả trong công tác xử lý rác
            thải.
          </TextEnteringEffect>

          <ImageEnteringEffect
            src={champion}
            height={"600px"}
            w={"100%"}
            objectFit={"cover"}
            containerProps={{ width: "full" }}
            initial={{ scaleX: 1, originX: 1 }}
            animate={{
              scaleX: 0,
              transition: {
                duration: 0.75,
                times: [0, 0.3, 1],
              },
            }}
          />
        </Col>
        <Row gap={"2rem"} flexDirection={{ base: "column", xl: "row" }}>
          <Box width={{ base: "full", xl: "40%" }}>
            <TextEnteringEffect
              durationValue={0.6}
              easeValue={[0.7, 0.3, 0.3, 0.7]}
              delayValue={0.07}
            >
              <Text
                fontSize="md"
                maxWidth="400px"
                mt={10}
                mb={8}
                textAlign={"justify"}
              >
                Các dự án Tech for Environment tại{" "}
                <Text as={"span"} fontWeight={"bold"}>
                  UniHack 2023
                </Text>{" "}
                sẽ được các đội thi tiếp tục hoàn thiện và phát triển, sớm được
                đưa vào ứng dụng trong thực tế, tạo bước đột phá trong lĩnh vực
                môi trường,{" "}
                <Text as={"span"} fontWeight={"bold"}>
                  vì một cuộc sống xanh và sự phát triển bền vững
                </Text>
                .
              </Text>
            </TextEnteringEffect>
          </Box>
          <Box width={{ base: "full", xl: "60%" }}>
            <ImageEnteringEffect
              src={final}
              height={"700px"}
              width={{ base: "full", xl: "60%" }}
              objectFit={"cover"}
              containerProps={{ width: "full" }}
              initial={{ scaleY: 1, originY: 1 }}
              animate={{
                scaleY: 0,
                transition: {
                  duration: 0.75,
                  times: [0, 0.3, 1],
                },
              }}
            />
          </Box>
        </Row>
      </Col>
      <Box
        padding={{ base: "2rem", md: "5rem", xl: "7rem", "2xl": "9rem" }}
        pt={{ base: "0", md: "0", xl: "0", "2xl": "0" }}
      >
        <TextEnteringEffect
          durationValue={0.6}
          containerProps={{ overflow: "unset" }}
        >
          <Flex
            as={motion.div}
            gap={"1rem"}
            alignItems={"center"}
            whileHover="hover"
            cursor={"pointer"}
          >
            <Box as={motion.div} variants={iconVariants}>
              <BsArrowLeft style={{ fontSize: "1.5rem" }} />
            </Box>

            <ChakraLink
              as={ReactRouterLink}
              to={PAGES.EVENTS_PAGE}
              onClick={handlePressDrawerItem}
              _hover={{ textDecoration: "none" }}
            >
              <Text fontSize="1rem" fontWeight="bold">
                Back to the events
              </Text>
            </ChakraLink>
          </Flex>
        </TextEnteringEffect>
      </Box>
    </Box>
  );
}

import { Box, Text, Image, theme, useMediaQuery } from "@chakra-ui/react";
import { Col, Row } from "components/elements";
import { cover1 } from "assets/images";
import { BsArrowDown } from "react-icons/bs";
import { motion } from "framer-motion";
import { TextEnteringEffect } from "components/shared/TextEnteringEffect";
import { BREAKPOINTS } from "constants/app";
import { AppTitle } from "components/elements/AppTitle";
import { colors } from "theme";

const HeroSection = () => {
  const [isDesktop] = useMediaQuery(`(min-width: ${BREAKPOINTS["XL"]})`, {
    ssr: false,
  });

  return (
    <Col
      justifyContent="flex-end"
      height={{ base: "max-content", "2xl": "calc(100vh - 80px)" }}
    >
      <Col
        justifyContent="flex-end"
        pb={10}
        height={{ base: "unset", "2xl": "54.9%" }}
        pl={{ base: "1rem", sm: "2rem", "2xl": "9rem" }}
        pt={{ base: "6rem", "2xl": "7rem" }}
      >
        <TextEnteringEffect
          fontSize={{ base: "2rem", sm: "3rem", md: "4rem", "2xl": "6rem" }}
          lineHeight={{ base: "2rem", sm: "3rem", md: "4rem", "2xl": "6rem" }}
          fontWeight="700"
          durationValue={1.4}
          delayValue={0.27}
        >
          <AppTitle>BY COMMUNITY</AppTitle>
        </TextEnteringEffect>
        <TextEnteringEffect
          fontSize={{ base: "2rem", sm: "3rem", md: "4rem", "2xl": "6rem" }}
          lineHeight={{ base: "2rem", sm: "3rem", md: "4rem", "2xl": "6rem" }}
          fontWeight="700"
          durationValue={1.4}
          delayValue={0.2}
        >
          <AppTitle>FOR COMMUNITY</AppTitle>
        </TextEnteringEffect>
      </Col>
      <Row
        overflow="hidden"
        flexGrow={1}
        flexDirection={{ base: "column", xl: "row" }}
        backgroundColor={colors.mineShaft}
      >
        <Col
          pos="relative"
          flex={1}
          backgroundColor="google.yellow"
          color={theme.colors.white}
          justifyContent="center"
          paddingX={{ base: "1rem", sm: "2rem", "2xl": "9rem" }}
          paddingY={{ base: "1rem", sm: "2rem", "2xl": "2rem" }}
          maxH={"400px"}
        >
          <TextEnteringEffect
            fontSize="3xl"
            fontWeight={600}
            mb={3}
            delayValue={0.25}
          >
            Google Developer Student Clubs
          </TextEnteringEffect>
          <TextEnteringEffect
            fontSize="md"
            color={theme.colors.black}
            delayValue={0.27}
            my={2}
          >
            Google Developer Student Clubs (GDSC) là chương trình toàn cầu của
            Google Developers dành cho sinh viên đam mê công nghệ tại các trường
            đại học, cao đẳng và các tổ chức giáo dục khác.
          </TextEnteringEffect>
          <TextEnteringEffect
            fontSize="md"
            color={theme.colors.black}
            delayValue={0.27}
          >
            Tự hào là một trong những chapter của GDSC, Google Developer Student
            Club - Danang University of Science and Technology (GDSC - DUT) là
            cộng đồng các bạn trẻ đam mê công nghệ cùng nhau học hỏi và xây dựng
            những giải pháp nhằm giải quyết các vấn đề tại địa phương thông qua
            các sự kiện và hoạt động từ nguồn tài nguyên của Google.
          </TextEnteringEffect>
          <motion.div
            initial={{ opacity: 0, y: "100px" }}
            animate={{
              opacity: 1,
              y: "0",
              transition: {
                duration: 0.5,
                ease: [1, 0, 0, 1],
                delay: 2,
              },
            }}
            style={{
              display: "flex",
              flexDirection: "column",
              position: "absolute",
              bottom: 10,
              right: 2,
              alignItems: "center",
            }}
          >
            <Text transform="rotate(90deg)" mb={4} fontSize="xs">
              Scroll
            </Text>
            <BsArrowDown />
          </motion.div>
        </Col>
        <Box
          as={motion.div}
          initial={{ width: "0" }}
          animate={{
            width: isDesktop ? "30vw" : "100vw",
            transition: {
              duration: 1,
              ease: [1, 0, 0, 1],
              delay: 1.2,
            },
          }}
          display="flex"
          flexDirection="row"
          height={"400px"}
        >
          <Image
            src={cover1}
            alt="hero-img"
            objectFit="cover"
            height="100%"
            width="100%"
          />
        </Box>
      </Row>
    </Col>
  );
};

export default HeroSection;

import { Col, Row } from "components/elements";
import { Box, Flex, Text, Link as ChakraLink } from "@chakra-ui/react";
import { Link as ReactRouterLink } from "react-router-dom";
import { product_homepage, product_homepage2 } from "assets/images";
import { AppTitle } from "components/elements/AppTitle";
import { TextEnteringEffect } from "components/shared/TextEnteringEffect";
import ImageEnteringEffect from "components/shared/ImageEnteringEffect";
import { AppDispatch } from "redux/root-store";
import { useDispatch } from "react-redux";
import { setShowLoadingScreen, showDrawer } from "redux/ui/slice";
import { PAGES } from "constants/app";

const Products = () => {
  const dispatch: AppDispatch = useDispatch();
  const handlePressDrawerItem = () => {
    dispatch(setShowLoadingScreen(true));
    dispatch(showDrawer(false));
  };
  return (
    <Row
      alignItems="flex-end"
      paddingX={{ base: "1rem", sm: "2rem", md: "5rem", "2xl": "7rem" }}
      marginTop={{ base: "6rem", "2xl": "9rem" }}
      gap={{ base: "2rem", lg: "4rem" }}
      flexDirection={{ base: "column-reverse", lg: "row" }}
    >
      <Box width={{ base: "full", lg: "50%" }} flexShrink={0}>
        <Flex width={"full"}>
          <ImageEnteringEffect
            src={product_homepage}
            minH={{ base: "600px", sm: "680px", "2xl": "800px" }}
            w={"full"}
            objectFit={"cover"}
            containerProps={{ width: "full" }}
            initial={{ scaleY: 1, originY: 1 }}
            animate={{
              scaleY: 0,
              transition: {
                duration: 0.75,
                times: [0, 0.3, 1],
              },
            }}
          />
        </Flex>
      </Box>
      <Col justifyContent={"flex-end"} width={{ base: "full", lg: "50%" }}>
        <Box mb={10}>
          <TextEnteringEffect durationValue={0.6}>
            <AppTitle>PRODUCTS</AppTitle>
          </TextEnteringEffect>
          <TextEnteringEffect
            durationValue={0.6}
            easeValue={[0.7, 0.3, 0.3, 0.7]}
            delayValue={0.07}
          >
            <Text
              fontSize="md"
              maxWidth="600px"
              mt={10}
              mb={8}
              textAlign={"justify"}
            >
              Bằng việc tận dụng nguồn tài nguyên của Google, GDSC - DUT đã,
              đang và không ngừng học hỏi và sáng tạo những sản phẩm nhằm giải
              quyết các vấn đề của cộng đồng. Nhiều dự án sẽ tiếp tục được phát
              triển và hoàn thiện, hứa hẹn sẽ là bước đột phá trong tương lai.
            </Text>
          </TextEnteringEffect>

          <ChakraLink
            as={ReactRouterLink}
            to={PAGES.PRODUCTS_PAGE}
            onClick={handlePressDrawerItem}
            _hover={{ textDecoration: "none" }}
          >
            <TextEnteringEffect
              durationValue={0.6}
              easeValue={[0.7, 0.3, 0.3, 0.7]}
              delayValue={0.1}
            >
              <Text
                fontStyle="italic"
                color="google.red"
                fontWeight="600"
                mb={8}
              >
                Sản phẩm của chúng tôi -&gt;
              </Text>
            </TextEnteringEffect>
          </ChakraLink>
        </Box>
        <Flex width={"full"}>
          <ImageEnteringEffect
            src={product_homepage2}
            maxH={"200px"}
            w={"full"}
            objectFit={"cover"}
            containerProps={{ width: "full" }}
            initial={{ scaleX: 1, originX: 1 }}
            animate={{
              scaleX: 0,
              transition: {
                duration: 0.75,
                times: [0, 0.3, 1],
              },
            }}
          />
        </Flex>
      </Col>
    </Row>
  );
};

export default Products;

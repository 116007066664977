import { PAGES } from "./app";

export const itemColorSchemes: Record<string, string> = {
  liked: "pink",
  watched: "green",
};

export const mainColorsSchemes = {
  [PAGES.HOME]: "google.yellow",
  [PAGES.PRODUCTS_PAGE]: "google.red",
  [PAGES.EVENTS_PAGE]: "google.blue",
  [PAGES.CONTACT_PAGE]: "google.green",
};

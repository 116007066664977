import { Box } from "@chakra-ui/react";
import HeroSection from "pages/home/components/HeroSection";
import About from "pages/home/components/About";
import Products from "pages/home/components/Products";
import Events from "pages/home/components/Events";
import Sponsors from "pages/home/components/Sponsors";
// import Contact from "pages/home/components/Contact";

const HomePage = () => {
  return (
    <Box>
      <HeroSection />
      <About />
      <Events />
      <Products />
      <Sponsors />
      {/* <Contact /> */}
    </Box>
  );
};

export default HomePage;

import { Box, Center, Icon, Link as LinkChakra, theme } from "@chakra-ui/react";
import { BasicRoute, Row } from "components/elements";
import { AppLink } from "components/elements/AppLink";
import { memo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { RootState } from "redux/root-reducer";
import { AppDispatch } from "redux/root-store";
import { setShowLoadingScreen, showDrawer } from "redux/ui/slice";
import { routes } from "routes";
import { AnimatePresence, motion } from "framer-motion";
import { drawerItemVars, drawerVars } from "constants/animation";
import { mainColorsSchemes } from "constants/color-scheme";
import { PAGES, SOCIAL_LINKS } from "constants/app";
import { colors } from "theme";

const renderMenuItems = (item: BasicRoute, parentPath = "", itemIdx?: number): React.ReactNode => {
  if (item.routes) {
    return item.routes.map((route, index) => {
      return renderMenuItems(route, parentPath, index);
    });
  }

  if (item.label) {
    const fullPath = parentPath && parentPath !== "/" ? `${parentPath}${item.path}` : item.path;

    return (
      <motion.div
        key={fullPath}
        variants={drawerItemVars}
        initial="initial"
        animate={{
          ...drawerItemVars.animate,
          transition: {
            duration: 0.1 + itemIdx / 8,
            ease: [0.32, 0, 0.39, 0],
            delay: 0.3,
          },
        }}
        exit={{
          ...drawerItemVars.exit,
          transition: {
            ease: [0.32, 0, 0.39, 0],
            duration: 0.1 + itemIdx / 10,
          },
        }}
      >
        <SingleMenuItem item={item} parentPath={parentPath} />
      </motion.div>
    );
  }

  return null;
};

const SingleMenuItem = ({ item, parentPath = "" }: { item: BasicRoute; parentPath: string }) => {
  const dispatch: AppDispatch = useDispatch();
  const { pathname } = useLocation();
  const fullPath = parentPath && parentPath !== PAGES.HOME ? `${parentPath}${item.path}` : item.path;
  const isActive =
    pathname !== PAGES.HOME && fullPath !== PAGES.HOME && pathname.includes(fullPath)
      ? true
      : pathname === PAGES.HOME && fullPath === PAGES.HOME
      ? true
      : false;

  const handlePressDrawerItem = () => {
    if (pathname !== fullPath) {
      dispatch(setShowLoadingScreen(true));
      dispatch(showDrawer(false));
    }
  };

  if (fullPath !== item.path) {
    return (
      <Box key={`menu-${fullPath}`} fontWeight="bold" my={2} _hover={{ cursor: "pointer" }} onClick={handlePressDrawerItem}>
        <AppLink as={Link} _hover={{ color: "main.700" }}>
          {item.label}
        </AppLink>
      </Box>
    );
  }

  return (
    <Box key={`menu-${fullPath}`} fontWeight="600" _hover={{ cursor: "pointer" }} my={5} onClick={handlePressDrawerItem}>
      <AppLink
        as={Link}
        _focus={{ boxShadow: "none" }}
        to={fullPath}
        color="black"
        position="relative"
        zIndex={2}
        fontSize={{ base: "2xl", lg: "4xl" }}
        letterSpacing="2px"
        _hover={{
          _before: {
            w: "110%",
          },
        }}
        _before={{
          content: "''",
          position: "absolute",
          top: "45%",
          left: -2,
          w: isActive ? "110%" : 0,
          h: 3,
          bg: mainColorsSchemes[item.path],
          opacity: 0.7,
          zIndex: -1,
          transition: "all 0.5s cubic-bezier(0.68, -0.55, 0.27, 1.55)",
          transitionOrigin: "left",
        }}
      >
        {item.label}
      </AppLink>
    </Box>
  );
};

export const DrawerNavigation = memo(() => {
  const isShowDrawer = useSelector((state: RootState) => state.ui.menu.isShowDrawer);

  return (
    <AnimatePresence>
      {isShowDrawer && (
        <motion.div
          variants={drawerVars}
          initial="initial"
          animate="animate"
          exit="exit"
          style={{
            zIndex: theme.zIndices.overlay,
            height: "100%",
          }}
        >
          <Box width="100%" height="100%" position={"relative"}>
            <Center width="100%" height="100%">
              {routes.map((item) => (
                <Box key={item.path}>{renderMenuItems(item)}</Box>
              ))}
            </Center>
            <Row position={"absolute"} bottom={5} left={"50%"} transform={"translateX(-50%)"} gap={"2rem"} display={{ base: "flex", lg: "none" }}>
              {SOCIAL_LINKS.map((link, itemIdx) => (
                <motion.div
                  key={link.url}
                  variants={drawerItemVars}
                  initial="initial"
                  animate={{
                    ...drawerItemVars.animate,
                    transition: {
                      duration: 0.1 + itemIdx / 8,
                      ease: [0.32, 0, 0.39, 0],
                      delay: 0.3,
                    },
                  }}
                  exit={{
                    ...drawerItemVars.exit,
                    transition: {
                      ease: [0.32, 0, 0.39, 0],
                      duration: 0.1 + itemIdx / 10,
                    },
                  }}
                >
                  <LinkChakra href={link.url} cursor="pointer" target="_blank" key={link.title}>
                    <Icon as={link.icon} boxSize={5} color={colors.primaryText} marginY={3} />
                  </LinkChakra>
                </motion.div>
              ))}
            </Row>
          </Box>
        </motion.div>
      )}
    </AnimatePresence>
  );
});

import {
  gdgCloud,
  gdgMienTrung,
  WTHcmc,
  gdsc,
  sGroup,
  PNV,
  DNES,
  fireGroup,
  paradox,
  enouvo,
  enlab,
  kms,
  mgm,
  OTSV,
  MTI,
  gear,
  codeGym,
  phongVu,
  audoStudio,
  vtc,
  SAptech,
  sophia,
  sdvn,
  bonPas,
  letOnline,
  NMPlaza,
  dut,
} from "assets/images";

export const SPONSORS = [
  {
    name: "GDG Cloud",
    img: gdgCloud,
    url: "",
    colorScheme: "google.red",
  },
  {
    name: "GDG Mientrung",
    img: gdgMienTrung,
    url: "",
    colorScheme: "google.yellow",
  },
  {
    name: "Women Techmaker HCMC",
    img: WTHcmc,
    url: "",
    colorScheme: "google.blue",
  },
  {
    name: "Cộng đồng GDSC Vietnam",
    img: gdsc,
    url: "",
    colorScheme: "google.green",
  },
  {
    name: "S-Group",
    img: sGroup,
    url: "",
    colorScheme: "google.red",
  },
  {
    name: "Passerelles Numériques Vietnam",
    img: PNV,
    url: "",
    colorScheme: "google.yellow",
  },
  {
    name: "DNES",
    img: DNES,
    url: "",
    colorScheme: "google.blue",
  },
  {
    name: "FireGroup Technology",
    img: fireGroup,
    url: "",
    colorScheme: "google.green",
  },
  {
    name: "Paradox Vietnam",
    img: paradox,
    url: "",
    colorScheme: "google.green",
  },
  {
    name: "Enouvo IT Solutions",
    img: enouvo,
    url: "",
    colorScheme: "google.blue",
  },
  {
    name: "Enlab Software",
    img: enlab,
    url: "",
    colorScheme: "google.yellow",
  },
  {
    name: "KMS Technology",
    img: kms,
    url: "",
    colorScheme: "google.red",
  },
  {
    name: "mgm partners technology Vietnam",
    img: mgm,
    url: "",
    colorScheme: "google.red",
  },
  {
    name: "ONE Tech Stop Vietnam",
    img: OTSV,
    url: "",
    colorScheme: "google.yellow",
  },
  {
    name: "MTI Technology",
    img: MTI,
    url: "",
    colorScheme: "google.blue",
  },
  {
    name: "Gear Inc.",
    img: gear,
    url: "",
    colorScheme: "google.green",
  },
  {
    name: "CodeGym Đà Nẵng",
    img: codeGym,
    url: "",
    colorScheme: "google.red",
  },
  {
    name: "Phong Vũ",
    img: phongVu,
    url: "",
    colorScheme: "google.yellow",
  },
  {
    name: "Audo Studio",
    img: audoStudio,
    url: "",
    colorScheme: "google.blue",
  },
  {
    name: "VTC Academy Danang Plus",
    img: vtc,
    url: "",
    colorScheme: "google.green",
  },
  {
    name: "Softech Aptech",
    img: SAptech,
    url: "",
    colorScheme: "google.green",
  },
  {
    name: "Sophia",
    img: sophia,
    url: "",
    colorScheme: "google.blue",
  },
  {
    name: "Áo thun SDVN",
    img: sdvn,
    url: "",
    colorScheme: "google.yellow",
  },
  {
    name: "BonPas Bakery & Coffee",
    img: bonPas,
    url: "",
    colorScheme: "google.red",
  },
  {
    name: "Let Online",
    img: letOnline,
    url: "",
    colorScheme: "google.green",
  },
  {
    name: "Như Minh Plaza Đà Nẵng",
    img: NMPlaza,
    url: "",
    colorScheme: "google.blue",
  },
  {
    name: "Trường Đại học Bách khoa Đà Nẵng",
    img: dut,
    url: "",
    colorScheme: "google.yellow",
  },
];

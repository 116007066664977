import {
  beebee,
  bloodbond,
  eventCook,
  happychild,
  myevents,
  provo,
  shortener,
  smartfood,
  unigo,
} from "assets/images";

export const PRODUCTS = [
  {
    name: "Unigo",
    description:
      "Hệ thống mô phỏng bản đồ trường Đại Học Bách Khoa - Đại học Đà Nẵng",
    img: unigo,
    colorScheme: "google.red",
  },
  {
    name: "BeeBee Travel",
    description:
      "Nền tảng (B2B) kết nối các doanh nghiệp cung cấp các dịch vụ du lịch",
    img: beebee,
    colorScheme: "google.green",
  },
  {
    name: "Provo",
    description:
      "Hệ sinh thái học tiếng Anh cung cấp các dịch vụ như: học từ vựng tiếng anh hay hỗ trợ sửa lỗi IELTS Writing,...",
    img: provo,
    colorScheme: "google.yellow",
  },
  {
    name: "GDSC - DUT URL Shortener",
    description: "Công cụ hỗ trợ rút gọn link được phát triển bởi GDSC - DUT",
    img: shortener,
    colorScheme: "google.blue",
  },
  {
    name: "Event Cookbook",
    description: "Cẩm nang tổ chức sự kiện",
    img: eventCook,
    colorScheme: "google.green",
  },
  {
    name: "Smartfood",
    description: "Ứng dụng gợi ý công thức nấu ăn từ thực phẩm có sẵn",
    img: smartfood,
    colorScheme: "google.yellow",
  },
  {
    name: "MyEvents",
    description: "Ứng dụng quản lý tổ chức sự kiện",
    img: myevents,
    colorScheme: "google.red",
  },
  {
    name: "HappyChild",
    description:
      "Nền tảng hỗ trợ phụ huynh xác định sớm, đánh giá và nuôi dạy trẻ tự kỷ",
    img: happychild,
    colorScheme: "google.blue",
  },

  {
    name: "BloodBond",
    description: "Nền tảng hỗ trợ công tác hiến máu",
    img: bloodbond,
    colorScheme: "google.red",
  },
  {
    name: "SharingHub",
    description: "Ứng dụng hỗ trợ hoạt động thiện nguyện",
    img: shortener,
    colorScheme: "google.yellow",
  },
];

import {
  dut_cloud,
  dut_cloud1,
  event_homepage,
  flutter_beyond,
  flutter_beyond_2,
  info2020,
  info2020_2,
  info2021,
  info2021_2,
  info2023_2,
  tech_transform,
  tech_transform_2,
  unihack2022,
  unihack2023,
} from "assets/images";

export const EVENTS = [
  {
    id: 1,
    name: "UniHack 2023",
    description: [
      "UniHack 2023 là cuộc thi hackathon dành cho các bạn sinh viên đam mê công nghệ trên toàn khu vực miền Trung, được tổ chức bởi Google Developer Student Club - Danang University of Science and Technology (GDSC - DUT) phối hợp cùng GDG Cloud Danang; với sự đồng hành của Đơn vị đồng tổ chức Trường Cao đẳng nghề Đà Nẵng, Đơn vị hỗ trợ là Passerelles numériques Việt Nam (PNV) và VTC Academy. Bên cạnh đó, sự góp mặt của NTT Vàng FireGroup Technology; NTT Bạc KMS Technology.",
      "Cuộc thi đã đạt được thành công trên cả mong đợi, tạo được nhiều ấn tượng tốt đẹp và cảm xúc trong lòng mỗi người với hơn 115 thí sinh thuộc 25 đội thi đến từ 13 trường Đại học tham gia, lập trình trong 24h liên tục với 10 sản phẩm được hoàn thiện với sự hỗ trợ của 21 Nhà tài trợ và Đối tác đồng hành cùng 22 anh chị Mentor và Speaker. Xuyên suốt cuộc thi, 6 sự kiện offline và 7 gian hàng triển lãm truyền thông doanh nghiệp đã diễn ra với hơn 500 người tham dự.",
    ],
    image: [unihack2023],
    colorScheme: "google.red",
    details: {
      name: "UniHack 2023",
    },
  },
  {
    id: 2,
    name: "UniHack 2022",
    description: [
      "UniHack 2022 là cuộc thi hackathon lần đầu tiên được tổ chức bởi trường Đại học Bách Khoa - Đại học Đà Nẵng phối hợp cùng Google Developer Student Club - Danang University of Science and Technology (GDSC - DUT) và đơn vị Let Agency thực hiện. Với sự đồng hành của BTCM Enouvo IT Solutions, NTT Vàng Code Gym, NTT Vàng mgm technology partners Vietnam, NTT Vàng One Tech Stop Vietnam, NTT Bạc Paradox Vietnam, NTT Bạc MTI Technology Vietnam, NTT Bạc KMS Technology, NTT Đồng Gear Inc, NTT Đồng Enlab Software và NTT F&B BonPas Bakery.",
      "Cuộc thi diễn ra thành công tốt đẹp với 16 đội thi với hơn 10 sản phẩm được hoàn thiện trong 30h coding dưới sự dẫn dắt của hơn 30 chuyên gia đầu ngành về lĩnh vực công nghệ và 32 Speaker, Ban giám khảo và Mentor. Trong suốt chuỗi sự kiện, có hơn 9 sự kiện offline đã diễn ra với hơn 300 người tham gia.",
    ],
    image: [unihack2022],
    colorScheme: "google.green",
  },
  {
    id: 3,
    name: 'Info Session 2023 - Talkshow: Người trong "ngành" - Kể chuyện "nghề',
    description: [],
    image: [event_homepage, info2023_2],
    colorScheme: "google.greenasdasd",
  },
  {
    id: 4,
    name: "Flutter Beyond Livestream Week",
    description: [],
    image: [flutter_beyond, flutter_beyond_2],
    colorScheme: "google.red",
  },
  {
    id: 5,
    name: "Talkshow: Chuyển hóa công nghệ - Cơ hội cho các ngành Non-Technical",
    description: [],
    image: [tech_transform, tech_transform_2],
    colorScheme: "google.green",
  },
  {
    id: 6,
    name: "Info Session 2021: Sinh viên với chuyện làm product",
    description: [],
    image: [info2021, info2021_2],
    colorScheme: "google.greenasdasd",
  },
  {
    id: 7,
    name: "Info Session 2020",
    description: [],
    image: [info2020, info2020_2],
    colorScheme: "google.greenasdasd",
  },
  {
    id: 8,
    name: "DSC-DUT: CLOUD STUDY JAM",
    description: [],
    image: [dut_cloud, dut_cloud1],
    colorScheme: "google.greenasdasd",
  },
];

import { Col } from "components/elements";
import { Box, Flex, Text, Link as ChakraLink } from "@chakra-ui/react";
import { Link as ReactRouterLink } from "react-router-dom";
import { event_homepage, event_homepage2 } from "assets/images";
import { AppTitle } from "components/elements/AppTitle";
import { TextEnteringEffect } from "components/shared/TextEnteringEffect";
import ImageEnteringEffect from "components/shared/ImageEnteringEffect";
import { PAGES } from "constants/app";
import { AppDispatch } from "redux/root-store";
import { setShowLoadingScreen, showDrawer } from "redux/ui/slice";
import { useDispatch } from "react-redux";

const Events = () => {
  const dispatch: AppDispatch = useDispatch();
  const handlePressDrawerItem = () => {
    dispatch(setShowLoadingScreen(true));
    dispatch(showDrawer(false));
  };
  return (
    <Box
      display="flex"
      flexDirection={{ base: "column", lg: "row" }}
      alignItems="center"
      paddingX={{ base: "1rem", sm: "2rem", md: "4rem", "2xl": "7rem" }}
      marginTop={{ base: "6rem", "2xl": "9rem" }}
      gap={{ base: "2rem", lg: "4rem", "2xl": "0" }}
    >
      <Box
        width={{ base: "full", lg: "50%" }}
        gap={"4rem"}
        textAlign={{ base: "left", lg: "right" }}
      >
        <Box width={{ base: "full", "2xl": "80%" }}>
          <TextEnteringEffect
            durationValue={0.6}
            containerProps={{ minHeight: "90px" }}
          >
            <AppTitle>EVENTS</AppTitle>
          </TextEnteringEffect>
          <TextEnteringEffect
            durationValue={0.6}
            delayValue={0.15}
            easeValue={[0.7, 0.3, 0.3, 0.7]}
          >
            <Text
              fontSize="md"
              mt={{ base: 5, "2xl": 10 }}
              mb={{ base: 10, "2xl": 20 }}
              textAlign={"justify"}
              sx={{ direction: "rtl" }}
            >
              Với sứ mệnh đã đặt ra, GDSC - DUT thường xuyên tổ chức các hoạt
              động, cuộc thi, sự kiện công nghệ không chỉ dành riêng cho các
              thành viên mà còn cho các bạn trẻ đam mê công nghệ. Các sự kiện đã
              khép lại với những thành công rực rỡ, để lại dấu ấn đậm nét, thổi
              bùng niềm đam mê sáng tạo và lập trình trong cộng đồng công
              nghệ.&lrm;
            </Text>
          </TextEnteringEffect>
          <ChakraLink
            as={ReactRouterLink}
            to={PAGES.EVENTS_PAGE}
            onClick={handlePressDrawerItem}
            _hover={{ textDecoration: "none" }}
          >
            <TextEnteringEffect
              durationValue={0.6}
              delayValue={0.2}
              easeValue={[0.7, 0.3, 0.3, 0.7]}
            >
              <Text fontStyle="italic" color="google.red" fontWeight="600">
                Sự kiện của chúng tôi -&gt;
              </Text>
            </TextEnteringEffect>
          </ChakraLink>
        </Box>
      </Box>
      <Col
        width={{ base: "full", lg: "50%" }}
        alignItems="flex-end"
        gap={"2rem"}
      >
        <Flex width={"full"}>
          <ImageEnteringEffect
            src={event_homepage}
            maxH={"340px"}
            w={"full"}
            objectFit={"cover"}
            containerProps={{ width: "full" }}
            initial={{ scaleX: 1, originX: 1 }}
            animate={{
              scaleX: 0,
              transition: {
                duration: 0.75,
                times: [0, 0.3, 1],
              },
            }}
          />
        </Flex>
        <Flex width={"full"}>
          <ImageEnteringEffect
            src={event_homepage2}
            maxH={"340px"}
            w={"full"}
            objectFit={"cover"}
            containerProps={{ width: "full" }}
            initial={{ scaleX: 1, originX: 1 }}
            animate={{
              scaleX: 0,
              transition: {
                duration: 0.75,
                times: [0, 0.3, 1],
              },
            }}
          />
        </Flex>
      </Col>
    </Box>
  );
};

export default Events;

import { Col, Row } from "components/elements";
import { Box, Grid, GridItem, Text, Image, Flex } from "@chakra-ui/react";
import { TextEnteringEffect } from "components/shared/TextEnteringEffect";
import { AppTitle } from "components/elements/AppTitle";
import { PRODUCTS } from "constants/products";
import { motion } from "framer-motion";
import TextStroke from "components/elements/TextStroke";

const ProductsPage = () => {
  return (
    <Col>
      <Flex justifyContent={"space-between"} alignItems={"center"}>
        <Row
          width={{ base: "full", xl: "60%" }}
          mt={{ base: "6rem", md: "10rem" }}
          mb={{ base: "5rem", lg: "10rem" }}
          paddingLeft={{
            base: "2rem",
            sm: "4rem",
            md: "6rem",
            lg: "0",
            xl: "5rem",
          }}
          pr={{ base: "2rem", sm: "4rem", md: "6rem", lg: "0" }}
          flexDirection={{ base: "column", md: "row" }}
          gap={"3rem"}
        >
          <TextEnteringEffect durationValue={0.6} delayValue={1}>
            <AppTitle>PRODUCTS</AppTitle>
          </TextEnteringEffect>

          <Box>
            <TextEnteringEffect
              durationValue={0.6}
              easeValue={[0.7, 0.3, 0.3, 0.7]}
              delayValue={1}
            >
              <Text fontSize="md" maxWidth="400px" textAlign={"justify"}>
                Bằng việc tận dụng nguồn tài nguyên của Google, GDSC - DUT đã,
                đang và không ngừng học hỏi và sáng tạo những sản phẩm nhằm giải
                quyết các vấn đề của cộng đồng. Nhiều dự án sẽ tiếp tục được
                phát triển và hoàn thiện, hứa hẹn sẽ là bước đột phá trong tương
                lai.
              </Text>
            </TextEnteringEffect>
          </Box>
        </Row>

        <TextEnteringEffect
          durationValue={0.6}
          delayValue={0.5}
          easeValue={[0.7, 0.3, 0.3, 0.7]}
          direction="horizontal"
          containerProps={{ display: { base: "none", xl: "block" } }}
        >
          <TextStroke title="Products" />
        </TextEnteringEffect>
      </Flex>
      <Grid
        templateColumns={{
          base: "repeat(1, 1fr)",
          md: "repeat(2, 1fr)",
          lg: "repeat(3, 1fr)",
        }}
      >
        {PRODUCTS.map((product) => (
          <GridItem
            as={motion.div}
            key={product.name}
            w={"full"}
            position="relative"
            cursor="pointer"
            color="white"
            whileHover="hover"
          >
            <Image
              height="100%"
              width="100%"
              objectFit="cover"
              src={product.img}
            />
            <Box
              position="absolute"
              zIndex={2}
              bottom={10}
              left={10}
              right={10}
            >
              <Text
                as={motion.h1}
                variants={{
                  hover: {
                    height: "fit-content",
                    opacity: 1,
                    transition: {
                      duration: 0.5,
                      ease: [1, 0, 0, 1],
                    },
                  },
                }}
                opacity={0}
                height={0}
                fontSize="35px"
                fontWeight="600"
              >
                {product.name}
              </Text>
              <Text
                as={motion.p}
                variants={{
                  hover: {
                    height: "fit-content",
                    opacity: 1,
                    transition: {
                      duration: 0.5,
                      ease: [1, 0, 0, 1],
                    },
                  },
                }}
                opacity={0}
                fontSize="20px"
                fontWeight="500"
                height={0}
              >
                {product.description}
              </Text>
            </Box>
            <Box
              as={motion.div}
              variants={{
                hover: {
                  height: "100%",
                  transition: {
                    duration: 0.5,
                    ease: [1, 0, 0, 1],
                  },
                },
              }}
              position="absolute"
              bottom={0}
              left={0}
              width="100%"
              height={0}
              backgroundColor={product.colorScheme}
              zIndex={1}
            />
          </GridItem>
        ))}
      </Grid>
    </Col>
  );
};

export default ProductsPage;

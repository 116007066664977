import { BasicRoute } from "components/elements/AppRouter";
import Layout from "components/layouts/Layout";
import { PAGES } from "constants/app";
import EventsPage from "pages/events";
import EventDetails from "pages/events/page/event-details";
import HomePage from "pages/home/page";
import ProductsPage from "pages/products";

export const routes: BasicRoute[] = [
  {
    path: PAGES.HOME,
    component: Layout,
    exact: false,
    routes: [
      {
        path: PAGES.HOME,
        label: "HOME",
        component: HomePage,
        exact: true,
        class: "google.yellow",
      },
      {
        path: PAGES.EVENTS_PAGE,
        label: "EVENTS",
        component: EventsPage,
        exact: true,
        class: "google.blue",
      },
      { path: PAGES.EVENTS_PAGE_DETAILS, component: EventDetails, exact: true },
      {
        path: PAGES.PRODUCTS_PAGE,
        label: "PRODUCTS",
        component: ProductsPage,
        exact: true,
        class: "google.red",
      },

      // {
      //   path: PAGES.CONTACT_PAGE,
      //   label: "Contact",
      //   component: ContactPage,
      //   exact: true,
      //   class: "google.green",
      // },
    ],
  },
];

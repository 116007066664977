import { Col } from "components/elements";
import { Grid, GridItem, Image } from "@chakra-ui/react";
import { SPONSORS } from "constants/sponsors";
import { AppTitle } from "components/elements/AppTitle";
import { TextEnteringEffect } from "components/shared/TextEnteringEffect";
import { gdscIcon } from "assets/images";

const Sponsors = () => {
  return (
    <Col
      alignItems="center"
      paddingX={{ base: "1rem", sm: "2rem", md: "5rem", "2xl": "7rem" }}
      pb={"4rem"}
      marginTop={{ base: "6rem", "2xl": "9rem" }}
      gap={{ base: "3rem", "2xl": "6rem" }}
    >
      <Col
        width="100%"
        justifyContent={"center"}
        alignItems={"center"}
        gap={{ base: "1rem", "2xl": "2rem" }}
      >
        <TextEnteringEffect durationValue={0.6}>
          <AppTitle>SPONSOR</AppTitle>
        </TextEnteringEffect>
      </Col>
      <TextEnteringEffect durationValue={1} easeValue={[1, 1, 1, 1]}>
        <Grid
          templateColumns={{
            base: "repeat(2, 1fr)",
            md: "repeat(3, 1fr)",
            lg: "repeat(4, 1fr)",
            "2xl": "repeat(6, 1fr)",
          }}
          columnGap={"8rem"}
          rowGap={"5rem"}
        >
          {SPONSORS.map((sponsor, index) => (
            <GridItem key={index}>
              <Col justifyContent={"center"} alignItems={"center"}>
                <Image
                  w="120px"
                  h={"120px"}
                  objectFit={"contain"}
                  src={sponsor.img ? sponsor.img : gdscIcon}
                  alt="gdscIcon"
                />
              </Col>
            </GridItem>
          ))}
        </Grid>
      </TextEnteringEffect>
    </Col>
  );
};

export default Sponsors;

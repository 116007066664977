import { FaPhoneAlt } from "react-icons/fa";
import { IoIosMail } from "react-icons/io";
import { Box, Link, Image, Text, LinkProps, Grid } from "@chakra-ui/react";
import { gdscIconFull } from "assets/images";
import { Col, Row } from "components/elements";
import { SOCIAL_LINKS } from "constants/app";

const CustomLink = ({ children, ...props }: LinkProps) => {
  return (
    <Link
      href="#"
      fontSize="sm"
      _hover={{ textDecoration: "underline" }}
      {...props}
      target="_blank"
    >
      {children}
    </Link>
  );
};

const Footer = () => {
  return (
    <Box
      px={{ base: "1rem", sm: "3rem", md: "5rem", "2xl": "7rem" }}
      pt={"4rem"}
      pb="2rem"
      marginInline="auto"
      backgroundColor="white"
    >
      <Row
        justifyContent="space-between"
        direction={{ base: "column", lg: "row" }}
        alignItems={"flex-start"}
        gap={"1rem"}
      >
        <Col
          maxW={{ base: "full", lg: "300px" }}
          alignItems={"center"}
          flexDirection={{ base: "row", lg: "column" }}
        >
          <Link href="" isExternal>
            <Image w="220px" src={gdscIconFull} alt="gdscIcon" />
          </Link>
        </Col>
        <Row
          justifyContent={{ sm: "space-between", md: "normal" }}
          direction={{ base: "column", md: "row" }}
          gap={{ base: "2rem", md: "4rem", xl: "6rem" }}
        >
          <Col alignItems="flex-start" gap={"0.5rem"}>
            <Text fontSize="md" fontWeight="bold">
              Contact Information
            </Text>
            <Col alignItems="flex-start" color="gray.500" gap={"0.25rem"}>
              <Row gap={"1rem"} alignItems={"center"}>
                <IoIosMail />
                <Text fontSize={"sm"}>partnership.gdscdut@gmail.com</Text>
              </Row>
              <Row gap={"1rem"} alignItems={"center"}>
                <FaPhoneAlt />
                <Text fontSize={"sm"}>(+84) 969 273 748</Text>
              </Row>
            </Col>
          </Col>
          <Col alignItems="flex-start" gap={"0.25rem"}>
            <Text fontSize="md" fontWeight="bold">
              Community
            </Text>
            <Grid
              templateColumns={{ base: "repeat(2, 1fr)", lg: "repeat(1, 1fr)" }}
              columnGap={"1rem"}
              alignItems="flex-start"
              color="gray.500"
              gap={"0.25rem"}
            >
              {SOCIAL_LINKS.map((link) => (
                <CustomLink href={link.url} key={link.title}>
                  {link.description}
                </CustomLink>
              ))}
            </Grid>
          </Col>
        </Row>
      </Row>
    </Box>
  );
};

export default Footer;

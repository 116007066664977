import {
  FaFacebookF,
  FaGithub,
  FaInstagram,
  FaLinkedinIn,
} from "react-icons/fa";

export const PAGES = {
  HOME: "/",
  PRODUCTS_PAGE: "/products",
  EVENTS_PAGE: "/events",
  EVENTS_PAGE_DETAILS: "/events/:id",
  CONTACT_PAGE: "/contact",
  TEST_PAGE: "/test-page",
};

export const SCROLL_TYPE = {
  UP: "up",
  DOWN: "down",
} as const;

export const QUERY_MOBILE = "32em";
export const QUERY_LG_DESKTOP = "1250px";

export const BREAKPOINTS = {
  BASE: "0em", // 0px
  SM: "30em", // ~480px. em is a relative unit and is dependant on the font size.
  MD: "48em", // ~768px
  LG: "62em", // ~992px
  XL: "80em", // ~1280px
  "2XL": "96em", // ~1536px
};

export const INIT_LOADING_CONTENT = "By community, for community";

// TODO: need update
export const SOCIAL_LINKS = [
  {
    title: "Facebook",
    icon: FaFacebookF,
    url: "https://www.facebook.com/gdsc.dut",
    description: "Theo dõi trên Facebook",
  },
  {
    title: "Instagram",
    icon: FaInstagram,
    url: "https://www.instagram.com/gdsc_dut",
    description: "Theo dõi trên Instagram",
  },
  {
    title: "Linkedin",
    icon: FaLinkedinIn,
    url: "https://www.linkedin.com/company/gdscdut/",
    description: "Theo dõi trên Linkedin",
  },
  {
    title: "Github",
    icon: FaGithub,
    url: "https://github.com/dscdut",
    description: "Theo dõi trên Github",
  },
];
